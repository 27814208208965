

.container {
  padding-bottom: 20px;
  min-height: calc(70vh - 120px);
  width: 96%;
  margin-left: 2%;
  border-radius: 8px;
  /* have small buffer at the bottom of the page */
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, #672f90 100%); */
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/under_hood_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 40px;
}

/* Centered text */
.centered {
  font-size: calc(10vw * .25 + 14px);
  font-weight: normal;
  color: black;
  width: 86vw;
  margin-left: 7vw;
  position: relative;
}

.papered {
  /* background: rgba(255, 255, 255, .8); */
  /* background: #ABA8B2; */
  border-radius: 6;
  border: 1px solid black;
  padding: 14;
  font-family: Titillium, sans-serif;
  padding: 15px;
  border-radius: 8px;
}

.hiddenOnDesktop {
  display: none;
}

.hiddenOnMobile {
  display: block
}

@media screen and (max-width: 400px) and (min-width: 0px) {
  .container {
    /* background-image: linear-gradient(#2e163f); */
    width: 100vw;
    height: calc(100vh - 60px);
    margin-left: 0;
    border-radius: 0px;
  }

  .centered {
    font-size: calc(6vw * .25 + 14px);
    top: 40px;
    width: 86vw;
    margin-left: 7vw;
  }

  .hiddenOnDesktop {
    display: block;
  }

  .hiddenOnMobile {
    display: none;
  }
}

/* .MuiButton-root {
  color: white;
  border-color: white;
}

.MuiButton-outlined {
    color: white;
} */

.MuiButton-root:hover {
  border-color: #632c93;
}

.metalPortalHome {
  font-weight: normal;
  font-size: 15px;
}

