svg {
    display: block;
}

.register_button {
    font-size: 13px;
    cursor: pointer;
    color: purple;
    left: 0;
    margin-bottom: 10px;
}

.register_button:hover {
    text-decoration: underline;
}
