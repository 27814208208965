.typewriter {
    font-size: calc(20px + .6vw);
    color: white;
    font-weight: bolder;
    width: 100%;
    align-content: center;
    overflow-x: hidden;
}

.cursor {
    color: gold;
    font-size: 31px;
}

.typewriter_holder {
    top: 20px;
    z-index: 3000;
    margin-bottom: 30px;
}
