.centeredFooter {
    position: absolute;
    margin: 0;
    top: 50%;
    font-family: 'Bayon';
    width: 100%;
    font-size: 14px;
}

.footerContainer {
    position: relative;
    bottom: 0;
    left: 0;
    height: 176px;
    width: 100%;
    color: white;
    background-color: #121828;
}

.desktop {
    display: block !important;
}

.mobile {
    display: none !important;
}

@media screen and (max-width: 600px) {
   .footerContainer {
       height: 240px;
   }

   .centeredFooter {
       top: 10%;
   }

   .desktop {
       display: none !important;
   }

   .mobile {
       display: block !important;
   }
}


