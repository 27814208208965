.info_input {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/under_hood_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiInputLabel-root{
    color: rgba(0, 0, 0, 0.6) !important;
}

.MuiFilledInput-root:after {
    border-bottom-color: #632c93 !important;
}

.MuiOutlinedInput-root:hover {
    border-color: transparent;
}

.MuiFormHelperText-root {
    color: white !important;
    margin-left: 1px !important;
}

.MuiFilledInput-root {
    background-color: white !important;
    border-radius: 4px !important;
}
