.search_image {
    width: min(300px, 40vw);
    height: min(325px, 42vw);
    margin-left: calc((100vw - min(300px, 40vw)) / 2);
}

@media screen and (max-width: 4000px) and (min-width: 901px) {
    .catCard {
        font-size: 20px !important
    }

    .catText {
        margin: 10px;
    }

    .catMargin {
        padding: 10px;
    }
}

@media screen and (max-width: 900px) and (min-width: 601px) {
    .catCard {
        font-size: 20px !important
    }

    .catText {
        margin: none;
    }

    .catMargin {
        padding: 4px;
    }
}

@media screen and (max-width: 600px) {
    .catCard {
        font-size: 10px;
        padding: none;
    }

    .catText {
        margin: none;
    }

    .catMargin {
        padding: 1px;
    }

    .search_image {
        width: 50vw;
        margin-left: 25vw;
    }
}

.catText {
    overflow-wrap: break-word;
    color: black;
    font-weight: bold;
}

.itemCell {
    color: rgba(94, 109, 132, 1);
    display: table-cell;
    padding-right: 1em;
    font-size: 15px;
}

.catTable {
    color: black;
    font-size: 15px;
    list-style: none;
    display: table;
    padding-inline-start: 0px;
}


