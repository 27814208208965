.home_bg {
    /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url('https://teamstepup.com/wp-content/uploads/2018/02/challenges-industrial-marketing.jpeg'); */
    background-size: cover;
    background-color: white;
    filter: blur(3px);
}

.metal_price_column {
    border-right: 1px solid black;
}

.featureGrid {
    padding-left: 50px;
    padding-right: 50px;
}

.homeText {
    margin-bottom: 20vh;
    text-align: center;
}



.homebg {
    margin-top: 60px;
    padding-bottom: 20px;
    min-height: calc(70vh - 120px);
    width: 96%;
    margin-left: 2%;
    border-radius: 8px;
    /* have small buffer at the bottom of the page */
    /* background: rgb(2,0,36); */
    /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, #672f90 100%); */
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/under_hood_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 40px;
}

.react-multi-carousel-track {
    margin-bottom: 30px !important;
}

.react-multi-carousel-dot--active button{
    background-color: #632c93 !important;
}

@media screen and (max-width: 900px) {
    .metal_price_column {
        border-right: none;
    }

    .featureGrid {
        padding-left: 10px;
        padding-right: 10px;
    }

    .homeText {
        margin-bottom: 300px;
    }

    .homebg {
        width: 100%;
        margin: 0;
    }
}

.home_rectangle {
    position: absolute;
    top: 175px;
    text-align: center;
    height: 200px;
    left: 10%;
    width: 80%;
    background: white;
}

.typewriter p {
    margin: none;
    color: black;
    font-size: calc(14px + .5vw);
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    /* border-right: .15em solid orange; The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(30, end)
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
  }

.slide_left_first {
    animation: moveToRight 1s ease-in-out;
    animation-delay: 1s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.slide_left_second {
    animation: moveToRight 1s ease-in-out;
    opacity: 0;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

.slide_left_third {
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.slide_left_fourth {
    animation: moveToRight 1s ease-in-out;
    animation-delay: 4s;
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes moveToRight {
    0% {
        transform: translateX(-105%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.catcard {
    font-size: 20px;
    color: black;
    font-family: "Bayon";
    font-style: regular;
    align-content: left;
    vertical-align: top;
    font-weight: 400;
    margin-left: 30px;
}

.image_item {
    /* padding-right: 20px; */
}




