.nav_bar {
    height: 60px;
}


.login {
    cursor: pointer;
    color: white;
}

.login:hover {
    color: grey;
}

.nav_grid {
    font-size: 20px;
    padding-top:12px;
}

.MuiButton-root {
    color: red;
    border-color: white;
  }
  
  .MuiButton-outlined {
      color: red;
  }
  
  .MuiButton-root:hover {
    border-color: #632c93;
  }


